@import "~slick-carousel/slick/slick";
@import "~slick-carousel/slick/slick-theme";
@import "~@fancyapps/fancybox/dist/jquery.fancybox.min.css";
//@import "~compass-mixins/lib/compass/css3";
//@import "mixin";

/* HEADER START */
.header {
  margin-top: 100px;
  margin-bottom: 120px;
  min-height: 610px;

  .description {
    padding-top: 130px;

    .quote {
      background: url("../img/quote.svg") no-repeat;
      background-position: 40% 30%;
      padding: 20px;
      margin-bottom: 90px;
      text-align: right;

      p {
        font-size: 16px;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #2f3866;
        margin-bottom: 0px;

        &.author {
          font-size: 14px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.71;
          letter-spacing: normal;
          color: #2f3866;
          padding-top: 6px;
        }
      }
    }

    .btn-link {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #0c6adc;
      padding: 16px;

      &:hover {
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
      }
    }

    .btn-link:last-child {
      margin-left: 20px;
    }

    h5 {
      text-transform: uppercase;
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #2f3866;
    }

    h1 {
      text-transform: uppercase;
      font-size: 60px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.8px;
      color: #2f3866;
      margin-bottom: 45px;
    }

    p {
      font-size: 18px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.33;
      letter-spacing: normal;
      color: #2f3866;
      margin-bottom: 70px;
      max-width: 400px;
    }

    @media (max-width: 1200px) {
      h1 {
        font-size: 50px;
      }
      h5 {
        font-size: 14px;
      }
      p {
        font-size: 17px;
      }
      .btn-link {
        width: 165px;
      }
    }
  }


  .main-img {
    //width: 740px;
    //height: 610px;
    position: relative;

    img {
      position: absolute;
      left: 0;
      top: 81px;
      z-index: 5;
      max-width: 602px;
      max-height: 408px;
    }

    .decor {
      height: 610px;
      position: absolute;
      right: -15%;

      .block-green {
        width: 200px;
        height: 200px;
        background-color: #25ba49;
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 4;
      }

      .block-blue {
        width: 321px;
        height: 569px;
        background-color: #0c6adc;
        z-index: 3;
        position: absolute;
        right: 45px;
      }
    }
  }
}

/*.description button {*/
/*border: 1px solid #F97300;*/
/*background: #F97300;*/
/*color: #fff;*/
/*}*/

/* HEADER END */
/* FEATURES START */
.features {
  padding-top: 65px;
  padding-bottom: 45px;

  &.gray-background {
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    background-color: rgba(228, 229, 243, 0.21);
    //height: 530px;
    margin-left: 5vw;
    //margin-left: 10%;
  }

  .description {
    h1 {
      font-size: 42px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.14;
      letter-spacing: 0.9px;
      color: #2f3866;
      text-transform: uppercase;
      margin-bottom: 35px;
      max-width: 320px;
    }

    p {
      font-size: 24px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.42;
      letter-spacing: 0.5px;
      color: #2f3866;
      margin-bottom: 66px;
      max-width: 450px;
    }

    .line {
      width: 100px;
      height: 1px;
      border: solid 1px #0c6adc;
    }
  }

  .why {
    //padding-top: 40px;

    h4 {
      font-size: 18px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #2f3866;
      text-transform: uppercase;
      margin-bottom: 25px;
    }


    ul {
      padding-left: 0px !important;
      list-style: none;

      li {
        max-width: 500px;
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #2f3866;
        justify-content: flex-end;
        margin-bottom: 30px;
        position: relative;
        padding-left: 15px;

        &::before {
          content: "\25A0";
          color: #0c6adc;
          font-weight: bold;
          display: block;
          width: 1em;
          float: left;
          //position: relative;
          //top: -2px;
          position: absolute;
          top: -2px;
          left: 0;
        }
      }
    }
  }
}

/* FEATURES END */

/* CLIENTS START */
.clients {
  margin: 100px 0;

  .col-lg-12 {
    .slider {
      padding: 0;
    }
  }

  .clients-slider {
    display: flex;
    overflow: hidden;

    .slick-slide {
      margin: 0 20px 0 16px;
      //opacity: 0;

      &.slick-active {
        //opacity: 1;
        //transition: opacity ease-in-out 1s;
      }
    }

    .slide {
      //min-width: 205px;
      //width: 263px;
      position: relative;
      height: 200px;
      box-shadow: 0px 31px 44px -10px #dfdfe9;
      background-color: #ffffff;
      margin: 30px 16px 60px 30px;
      padding: 25px 25px;


      .block-img {
        //height: 70px;
        //width: 115px;
        //width: 200px;
        //height: 200px;
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          max-width: 200px;
          max-height: 200px;
          display: block;
          margin: 0 auto;
        }

        //img {
        //  position: absolute;
        //  bottom: 0;
        //  margin: 0 auto;
        //  left: 0;
        //  right: 0;
        //}
      }

      p {
        text-align: center;
        font-size: 12.6px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #000000;
      }

      .line {
        border-top: solid 1px #e4e5f3;
        width: 115px;
        margin: 45px auto 30px auto;
      }
    }
  }

  .head {
    margin-bottom: 30px;

    .col-lg-12 {
      display: flex;
      flex-wrap: nowrap;
      padding: 0 50px;

      div.head-h1 {
        justify-content: flex-start;

        h1 {
          max-width: 350px;
          white-space: nowrap;
          font-size: 42px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.14;
          letter-spacing: 0.9px;
          color: #2f3866;
        }
      }

      .arrows {
        display: flex;
        justify-content: flex-end;
        width: 100%;

        .arrow {
          display: flex;
          width: 62px;
          height: 62px;
          cursor: pointer;
          position: relative;

          &.left {
            background: url("../img/left-arrow.png") no-repeat 49% center;
            border: 1px solid #e4e5f3;
            border-radius: 100px;
          }

          &.right {
            margin-left: 20px;
            background: url("../img/right-arrow.png") no-repeat 51% center;
            border: 1px solid #e4e5f3;
            border-radius: 100px;
          }

          &:hover {
            border-color: #a8a8a8;
            transition: border-color ease 0.5s;
          }
        }
      }
    }
  }

  //@include timer('.arrow', 2, 62px, #fff, '2px solid rgba(0,0,0,0.5)');

  @media (min-width: 1200px) {
    .container {
      max-width: 1200px;
    }
  }
}

/* GALLERY START */
.gallery {
  margin: 100px 0;

  .col-lg-12 {
    &.slider {
      padding: 0 50px;
    }
  }

  .gallery-slider {
    display: flex;
    overflow: hidden;

    .slick-slide {
      margin: 0;
      opacity: 0;

      &.slick-active {
        opacity: 1;
        transition: opacity ease-in-out 1s;
      }
    }

    .slide {
      //min-width: 205px;
      //width: 263px;
      //height: 250px;
      //box-shadow: 0px 31px 44px -10px #dfdfe9;
      //background-color: #ffffff;
      //margin: 30px 16px 60px 30px;
      //padding: 25px 45px;

      .block-img {

        img {
          //width: 285px;
          max-height: 190px;
          //position: absolute;
          //bottom: 0;
          //margin: 0 auto;
          //left: 0;
          //right: 0;
        }
      }
    }
  }

  .head {
    margin-bottom: 20px;

    .col-lg-12 {
      display: flex;
      flex-wrap: nowrap;
      padding: 0 50px;

      div.head-h1 {
        justify-content: flex-start;

        h1 {
          max-width: 350px;
          white-space: nowrap;
          font-size: 42px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.14;
          letter-spacing: 0.9px;
          color: #2f3866;
        }
      }

      .arrows {
        display: flex;
        justify-content: flex-end;
        width: 100%;

        .arrow {
          display: flex;
          width: 62px;
          height: 62px;
          cursor: pointer;
          position: relative;

          &.left {
            background: url("../img/left-arrow.png") no-repeat 49% center;
            border: 1px solid #e4e5f3;
            border-radius: 100px;
          }

          &.right {
            margin-left: 20px;
            background: url("../img/right-arrow.png") no-repeat 51% center;
            border: 1px solid #e4e5f3;
            border-radius: 100px;
          }

          &:hover {
            border-color: #a8a8a8;
            transition: border-color ease 0.5s;
          }
        }
      }
    }
  }

  //@include timer('.arrow', 2, 62px, #fff, '2px solid rgba(0,0,0,0.5)');

  @media (min-width: 1200px) {
    .container {
      max-width: 1200px;
    }
  }
}

/* GALLERY END */